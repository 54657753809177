import { React } from "react";
import { useTranslation } from "react-i18next";

export function DoublePageContact(props) {
  const { t } = useTranslation();

  return (
    <div className="double-page-text">
      <p>
        <b id="loc_c_info_t">{t("loc_c_info_t")}</b>
        <br />
        <br />
        Samuele Bischof
        <br />
        E-mail: <a href="mailto:samuele@bischof.cloud">samuele@bischof.cloud</a>
        <br />
        Homepage: <a href="https://samuelebischof.ch">samuelebischof.ch</a>
        <br />
        LinkedIn:{" "}
        <a href="https://www.linkedin.com/in/samuele-bischof/">
          samuele-bischof
        </a>
      </p>
    </div>
  );
}
