import { React } from "react";

export function DoublePageImage(props) {
  const {svg, bgImage, alt} = props;
  return (
    <div className="double-page-image" style={bgImage && {background: `url("${bgImage}") no-repeat center center fixed`}}>
      <img src={svg} alt={alt} />
    </div>
  );
}
