import { React } from "react";

export function VideoContainer(props) {
  const { src } = props;

  return (
    <div className="video-container">
      <video id="bgVideo" playsInline autoPlay muted loop>
        <source type="video/mp4" src={src} />
      </video>
    </div>
  );
}
