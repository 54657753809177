import { React } from "react";

export function SpacerImage(props) {
  const { image } = props;

  return (
    <div
      className="spacer-image"
      style={{ background: `url("${image}") no-repeat center center fixed` }}
    ></div>
  );
}
