import { React, useState } from "react";
import { default as classNames } from "classnames";
import { useTranslation } from "react-i18next";

export function TextLine(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { title, text, children } = props;

  return (
    <div className={classNames('text-line', isOpen && 'active')}>
      <button className="text-line__btn" onClick={() => setIsOpen(!isOpen)}>
        <span dangerouslySetInnerHTML={{ __html: t(title) }} />
        <span className="text-line__btn__toggle-sign"></span>
      </button>
      <div className={classNames("text-line__content", !isOpen && "text-line__content--collapsed")}>
        {text ?  <p className="text-line__content__inner" dangerouslySetInnerHTML={{ __html: t(text) }} /> : children}
      </div>
    </div>
  );
}
