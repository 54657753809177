import { Fragment, React, useEffect, useRef, useState } from "react";
import { TextLine } from "./TextLine/TextLine";
import { DoublePageImage } from "./DoublePageImage/DoublePageImage";
import { SpacerImage } from "./SpacerImage/SpacerImage";
import { VideoContainer } from "./VideoContainer/VideoContainer";
import { LandingPage } from "./LandingPage/LandingPage";
import { DoublePageContact } from "./DoublePageContact/DoublePageContact";
import Data from "../data/data.json";
import { default as classNames } from "classnames";

function DoublePageText(props) {
  return(
    <div className="double-page-text">{props.children}</div>
  );
}

function DoublePage(props) {
  const { invertDesktop, invertMobile, children, scrollTo } = props;
  const ref = useRef(null);

  useEffect(() => {
    if (scrollTo) {
      // ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollTo])

  return (
    <div ref={ref} className="double-page page">
      <div
        className={classNames(
          "double-page__inner",
          invertDesktop && "double-page__inner--invert",
          invertMobile && "double-page__inner--mobile-invert"
        )}
      >
        {children}
      </div>
    </div>
  );
}

function Container() {
  const [activePage, setActivePage] = useState(0);
  const containerRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      if (activePage === null) {
        setActivePage(1);
      } else if (activePage > Data.doublePages.length) {
        return;
      } else {
        setActivePage(activePage + 1);
      }
    } else if (e.key === 'ArrowUp') {
      if (activePage === null) {
        setActivePage(0);
      } else if (activePage === 0) {
        return;
      } else {
        setActivePage(activePage - 1);
      }
    }
  }

  const handleLanguageSelect = () => {
    setActivePage(1);
  }

  useEffect(() => {
    containerRef.current.focus();
  }, []);

  return (
    <div tabIndex='1' className="container" onKeyDown={handleKeyDown} ref={containerRef}>
      <LandingPage
        logoSrc="./assets/img/logo.svg"
        logoAlt="Samuele Bischof logo"
        scrollTo={activePage === 0}
        onLanguageSelect={handleLanguageSelect}
      />
      <VideoContainer src="./assets/img/bg.mp4" />

      {Data.doublePages.map((doublePage, i) => (
        <Fragment key={i}>
          <DoublePage invertDesktop={i % 2} scrollTo={i + 1 === activePage}>
            <DoublePageImage
              svg={doublePage.svgPath}
              bgImage={doublePage.bgImgPath}
              alt={doublePage.years}
            />
            <DoublePageText>
              {doublePage.textLines.map((textLine) => (
                <Fragment key={textLine.text}>
                  {textLine.text && (
                    <TextLine title={textLine.title} text={textLine.text} />
                  )}
                  {textLine.textLines && (
                    <TextLine title={textLine.title}>
                      {textLine.textLines.map((tLine) => (
                        <TextLine key={tLine.title} title={tLine.title} text={tLine.text} />
                      ))}
                    </TextLine>
                  )}
                </Fragment>
              ))}
            </DoublePageText>
          </DoublePage>
          <SpacerImage image={doublePage.bgImgPath} />
        </Fragment>
      ))}

      <DoublePage invertMobile scrollTo={Data.doublePages.length + 1 === activePage}>
        <DoublePageContact />
        <DoublePageImage svg="./assets/img/contact.svg" alt="contact" />
      </DoublePage>
    </div>
  );
}

export default Container;
