import { React, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { default as classNames } from "classnames";

const LANGUAGES = [
  { lang: "en", name: "English" },
  { lang: "de", name: "Deutsch" },
  { lang: "it", name: "Italiano" },
  { lang: "fr", name: "Français" },
];

export function LandingPage(props) {
  const { logoSrc, logoAlt, scrollTo, onLanguageSelect } = props;
  const { t, i18n } = useTranslation();
  const ref = useRef(null);

  useEffect(() => {
    if (scrollTo) {
      // ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollTo])

  return (
    <div className="landing-page page" ref={ref}>
      <div id="menu">
        <img id="logo" src={logoSrc} alt={logoAlt} />
        <ul id="language-menu">
          {/* <li id="language-text">
            <strong>{t('loc_language_select')}</strong>
          </li> */}
          {LANGUAGES.map((lang) => (
            <li
              key={lang.lang}
              id={lang.lang}
              className={classNames(i18n.language === lang.lang && "active")}
              onClick={() => {onLanguageSelect(); i18n.changeLanguage(lang.lang)}}
            >
              {t(lang.lang)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
